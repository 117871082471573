
import BackToStoreButton from "@/common-components/BackToStoreButton.vue";
import PaymentAvailableAttemptsView from "@/components/PaymentConfirmation/child-components/PaymentAvailableAttemptsView.vue";
import { CheckoutScreen } from "@/enums/CheckoutScreen";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as Mutations from "@/store/constants/BvrPayCheckout/Mutations";
import { computed, ComputedRef } from "vue";
import { useStore } from "vuex";

export default {
  name: "PaymentCancel",
  components: { PaymentAvailableAttemptsView, BackToStoreButton },
  setup() {
    const store = useStore();

    const availableConfirmationAttemptCount: ComputedRef<number> = computed(
      () =>
        store.getters[Getters.BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT]
    );

    const failureRedirectUrl: ComputedRef<string> = computed(
      () => store.getters[Getters.BVR__GET_CHECKOUT_DATA].failureRedirectUrl
    );

    const handlePaymentConfirmation = () => {
      store.commit(
        Mutations.BVR__SET_CURRENT_CHECKOUT_SCREEN,
        CheckoutScreen.PaymentConfirmation
      );
      store.dispatch(Actions.BVR__CONFIRM_PAYMENT);
    };

    return {
      handlePaymentConfirmation,
      availableConfirmationAttemptCount,
      failureRedirectUrl,
    };
  },
};
