
import { computed } from "vue";
import { ComputedRef } from "vue";

export default {
  name: "PaymentVerifyPanel",
  props: {
    sumAmount: Number,
  },
  setup(props: any) {
    const paymentVerifyText: ComputedRef<String> = computed(() => {
      return `Орієнтовна сума замовлення ${props.sumAmount} грн. Оплата пройде, щойно його зберуть.`;
    });

    return {
      paymentVerifyText,
    };
  },
};
