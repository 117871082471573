
import { useStore } from "vuex";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";

export default {
  name: "PaymentSuccess",
  setup() {
    const store = useStore();
    let globalDocument = document;

    store.dispatch(Actions.BVR__START_REDIRECT_TO_STORE_TIMER);

    const handleBackToStoreClick = () => {
      globalDocument.location.href = `${
        store.getters[Getters.BVR__GET_CHECKOUT_DATA].successRedirectUrl
      }`;
    };

    // Just for unit testing
    const mockGlobalDocument = (globalDocumentMock: any) => {
      globalDocument = globalDocumentMock;
    };

    return {
      mockGlobalDocument,
      handleBackToStoreClick,
    };
  },
};
