import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8f165e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-dialog-small-body" }
const _hoisted_2 = {
  id: "client-instructions",
  class: "main-user-message q-mt-md"
}
const _hoisted_3 = { style: {"margin-top":"20px","text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_payment_confirmation_dialog_header = _resolveComponent("payment-confirmation-dialog-header")!
  const _component_additional_bonus_ad_view = _resolveComponent("additional-bonus-ad-view")!
  const _component_payment_info_panel = _resolveComponent("payment-info-panel")!
  const _component_payment_verify_panel = _resolveComponent("payment-verify-panel")!
  const _component_phone_number_panel = _resolveComponent("phone-number-panel")!
  const _component_push_notification_send_delay_counter = _resolveComponent("push-notification-send-delay-counter")!
  const _component_payment_available_attempts_view = _resolveComponent("payment-available-attempts-view")!
  const _component_payment_error_view = _resolveComponent("payment-error-view")!
  const _component_payment_confirmation_control_panel = _resolveComponent("payment-confirmation-control-panel")!
  const _component_back_to_store_button = _resolveComponent("back-to-store-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_payment_confirmation_dialog_header, {
        ref: "paymentConfirmationDialogHeader",
        partnerSite: $setup.merchantInfo.merchantName,
        partnerOrderId: $setup.bvrCheckoutData.partnerOrderId,
        hideOrderNumber: $setup.hideOrderNumber
      }, null, 8, ["partnerSite", "partnerOrderId", "hideOrderNumber"]),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString($setup.headerCaption) + " ", 1),
        (!$setup.isLoyaltyPointsLoading)
          ? (_openBlock(), _createBlock(_component_additional_bonus_ad_view, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_payment_info_panel, {
        ref: "paymentInfoPanel",
        authType: $setup.bvrCheckoutData.authType,
        sumAmount: $setup.bvrCheckoutData.amount,
        isLoyaltyPointsLoading: $setup.isLoyaltyPointsLoading
      }, null, 8, ["authType", "sumAmount", "isLoyaltyPointsLoading"]),
      ($setup.isVerifyPayment)
        ? (_openBlock(), _createBlock(_component_payment_verify_panel, {
            key: 0,
            ref: "paymentVerifyPanel",
            id: "payment-verify-panel",
            sumAmount: $setup.bvrCheckoutData.amount
          }, null, 8, ["sumAmount"]))
        : _createCommentVNode("", true),
      _createVNode(_component_phone_number_panel, {
        ref: "phoneNumberPanel",
        phoneNumber: $setup.bvrCheckoutData.phoneNumber,
        isError: $setup.isPhoneNumberInputError,
        disable: !$setup.canChangePhoneNumber,
        errorMessage: $setup.phoneNumberInputErrorMessage,
        onInput: _cache[0] || (_cache[0] = (value) => $setup.handlePhoneNumberInput(value))
      }, null, 8, ["phoneNumber", "isError", "disable", "errorMessage"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.isApproveButtonDisabled && !$setup.isApproveError && !$setup.isPaymentSuspend && $setup.availableConfirmationAttemptCount)
        ? (_openBlock(), _createBlock(_component_push_notification_send_delay_counter, {
            key: 0,
            ref: "pushNotificationSendDelayCounter",
            timeCounterValue: $setup.pushSuspensionTime
          }, null, 8, ["timeCounterValue"]))
        : _createCommentVNode("", true),
      (!$setup.isApproveButtonDisabled && !$setup.isApproveError && !$setup.isPaymentSuspend)
        ? (_openBlock(), _createBlock(_component_payment_available_attempts_view, {
            key: 1,
            ref: "paymentAvailableAttemptsView",
            attemptsAvailable: $setup.availableConfirmationAttemptCount
          }, null, 8, ["attemptsAvailable"]))
        : _createCommentVNode("", true),
      ($setup.isApproveError)
        ? (_openBlock(), _createBlock(_component_payment_error_view, {
            key: 2,
            ref: "paymentErrorView",
            errorCode: $setup.errorCode
          }, null, 8, ["errorCode"]))
        : _createCommentVNode("", true)
    ]),
    ($setup.availableConfirmationAttemptCount)
      ? (_openBlock(), _createBlock(_component_payment_confirmation_control_panel, {
          key: 0,
          id: "payment-confirmation-control-panel",
          ref: "paymentConfirmationControlPanel",
          onConfirm: _cache[1] || (_cache[1] = ($event: any) => ($setup.handlePaymentConfirmation())),
          buttonLabel: "Надіслати повторно",
          isWaiting: $setup.isPaymentSuspend,
          disabled: $setup.isApproveButtonDisabled
        }, null, 8, ["isWaiting", "disabled"]))
      : _createCommentVNode("", true),
    (!$setup.availableConfirmationAttemptCount)
      ? (_openBlock(), _createBlock(_component_back_to_store_button, {
          key: 1,
          url: $setup.failureRedirectUrl
        }, null, 8, ["url"]))
      : _createCommentVNode("", true)
  ], 64))
}