
import PageContentContainer from "@/common-components/PageContentContainer.vue";
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import PaymentAttemptsEnded from "@/components/PaymentAttemptsEnded/PaymentAttemptsEnded.vue";
import PaymentCancel from "@/components/PaymentCancel/PaymentCancel.vue";
import PaymentConfirmation from "@/components/PaymentConfirmation/PaymentConfirmation.vue";
import PaymentDialogSmall from "@/components/PaymentDialogSmall/PaymentDialogSmall.vue";
import PaymentError from "@/components/PaymentError/PaymentError.vue";
import PaymentSuccess from "@/components/PaymentSuccess/PaymentSuccess.vue";
import PaymentTimeoutFail from "@/components/PaymentTimeoutFail/PaymentTimeoutFail.vue";
import { CheckoutScreen } from "@/enums/CheckoutScreen";
import router from "@/router";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as CommonActions from "@/store/constants/Common/Actions";
import { computed, ComputedRef, onMounted } from "vue";
import { useStore } from "vuex";

export default {
    name: "BvrPayCheckout",
    components: {
        Header,
        Footer,
        PageContentContainer,
        PaymentConfirmation,
        PaymentTimeoutFail,
        PaymentSuccess,
        PaymentDialogSmall,
        PaymentAttemptsEnded,
        PaymentError,
        PaymentCancel,
    },
    setup() {
        const store = useStore();
        const checkoutId = router.currentRoute.value.params.id;

        onMounted(async () => {
            store.dispatch(CommonActions.COMMON__INIT_WEB_SOCKET, checkoutId)
            .then(() => {
                store.dispatch(Actions.BVR__LOAD_CHECKOUT_DATA, checkoutId)});
                await store.dispatch(CommonActions.COMMON__LOAD_CHECKOUT_OPTIONS);
        });

        const isLoading: ComputedRef<boolean> = computed(
            () => store.getters[Getters.BVR__GET_IS_CHECKOUT_WAITING]
        );

        const currentScreen: ComputedRef<number> = computed(
            () => store.getters[Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN]
        );

        const onBackToStoreClick = () => {
            store.dispatch(Actions.BVR__REDIRECT_TO_STORE);
        };

        return {
            isLoading,
            currentScreen,
            CheckoutScreen,
            onBackToStoreClick,
        };
    },
};
