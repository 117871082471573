import { computed } from '@vue/reactivity';
import Platform from 'quasar/src/plugins/platform/Platform.js';;
import { ComputedRef } from 'vue';

const MINIMAL_DESCTOP_SCREEN_WIDTH = 1350;

export default {
  name: "PushConfirmReminder",
  props: {
    isActive: Boolean,
  },
  setup() {
    let _paltformMock = null;
    let _windowMock = null;

    /* istanbul ignore next */
    const setupMock = (platformMock: any, windowMock: any) => {
      _paltformMock = platformMock;
      _windowMock = windowMock;
    }

    const isMobile: ComputedRef<boolean> = computed(
      () => {
        let platform: any = Platform;

        if(_paltformMock) {
          platform = _paltformMock
        }
        return !platform.is.desktop
      }
    );

    const isPortrait: ComputedRef<boolean> = computed(() => {
        let windowObject = window;

        if(_windowMock) {
          windowObject = _windowMock;
        }
        return windowObject.matchMedia("(orientation: portrait)").matches === true
      }
    );

    const isNarrowScreen: ComputedRef<boolean> = computed(
      () => document.documentElement.clientWidth < MINIMAL_DESCTOP_SCREEN_WIDTH
    )

    return {
      isMobile,
      isPortrait,
      isNarrowScreen,
      setupMock // for unit-testing
    }
  }
};
