import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_payment_confirmation = _resolveComponent("payment-confirmation")!
  const _component_payment_timeout_fail = _resolveComponent("payment-timeout-fail")!
  const _component_payment_success = _resolveComponent("payment-success")!
  const _component_payment_attempts_ended = _resolveComponent("payment-attempts-ended")!
  const _component_payment_error = _resolveComponent("payment-error")!
  const _component_PaymentCancel = _resolveComponent("PaymentCancel")!
  const _component_payment_dialog_small = _resolveComponent("payment-dialog-small")!
  const _component_page_content_container = _resolveComponent("page-content-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "lHh Lpr lFf",
    class: "layout"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Header, { sectionDescription: "Сплата" }),
      _createVNode(_component_page_content_container, { class: "row items-center justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_payment_dialog_small, { isLoading: $setup.isLoading }, {
            default: _withCtx(() => [
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentConfirmation)
                ? (_openBlock(), _createBlock(_component_payment_confirmation, { key: 0 }))
                : _createCommentVNode("", true),
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentFail)
                ? (_openBlock(), _createBlock(_component_payment_timeout_fail, { key: 1 }))
                : _createCommentVNode("", true),
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentSuccess)
                ? (_openBlock(), _createBlock(_component_payment_success, { key: 2 }))
                : _createCommentVNode("", true),
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentAttemptsEnded)
                ? (_openBlock(), _createBlock(_component_payment_attempts_ended, { key: 3 }))
                : _createCommentVNode("", true),
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentError)
                ? (_openBlock(), _createBlock(_component_payment_error, { key: 4 }))
                : _createCommentVNode("", true),
              ($setup.currentScreen === $setup.CheckoutScreen.PaymentCancel)
                ? (_openBlock(), _createBlock(_component_PaymentCancel, { key: 5 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["isLoading"])
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ]),
    _: 1
  }))
}