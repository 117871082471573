

export default {
    name: "BackToStoreButton",
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        let globalDocument = document;

        const handleBackToStoreClick = () => {
            globalDocument.location.href = props.url;
        };

        return {
            handleBackToStoreClick,
        };
    },
};
