import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_btn, {
      ref: "confirmPaymentButton",
      "no-caps": "",
      label: $props.buttonLabel,
      loading: $props.isWaiting,
      id: "payment-confirm-button",
      class: _normalizeClass(["layout dialog-button", { disabled: $setup.isDisabled }]),
      "text-color": $setup.isDisabled ? 'grey-8' : 'white',
      flat: $setup.isDisabled,
      disable: $setup.isDisabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_q_spinner_gears, { class: "on-left" }),
        _createTextVNode(" Обробка... ")
      ]),
      _: 1
    }, 8, ["label", "loading", "class", "text-color", "flat", "disable"])
  ]))
}