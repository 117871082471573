
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import { computed, ComputedRef } from "vue";
import { useStore } from "vuex";

export default {
  name: "PaymentError",
  setup() {
    const store = useStore();
    let globalDocument = document;

    const errorCode: ComputedRef<string> = computed(
      () => store.getters[Getters.BVR__GET_APPROVE_ERROR]
    );

    const handleBackToStoreClick = () => {
      globalDocument.location.href = `${
        store.getters[Getters.BVR__GET_CHECKOUT_DATA].failureRedirectUrl
      }`;
    };

    // Just for unit testing
    const mockGlobalDocument = (globalDocumentMock: any) => {
      globalDocument = globalDocumentMock;
    };

    return {
      handleBackToStoreClick,
      mockGlobalDocument,
      errorCode,
    };
  },
};
