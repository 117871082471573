
import { computed, ComputedRef } from "vue";

export default {
  name: "PaymentAvailableAttemptsView",
  props: {
    attemptsAvailable: Number,
  },
  setup(props: any) {
    const attemptsWordRestVariant: ComputedRef<String> = computed(() => {
      if(!props.attemptsAvailable) {
        return "Залишилось";
      }
      const lastDigit: number = parseInt(
        props.attemptsAvailable.toString().slice(-1)
      );
      if (lastDigit == 1) {
        return "Залишилась";
      }
      return "Залишилося";
    });

    const attemptsWordVariant: ComputedRef<String> = computed(() => {
      let variant = "спроб";

      if(!props.attemptsAvailable) {
        return variant;
      }

      const lastDigit: number = parseInt(
        props.attemptsAvailable.toString().slice(-1)
      );

      if (props.attemptsAvailable >= 5 && props.attemptsAvailable <= 20) {
        return "спроб";
      }
      if (lastDigit == 1) {
        return "спроба";
      }
      if (lastDigit > 1 && lastDigit < 5) {
        return "спроби";
      }

      return variant;
    });

    return {
      attemptsWordVariant,
      attemptsWordRestVariant,
    };
  },
};
