
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import { useStore } from "vuex";

export default {
  name: "PaymentFail",
  setup() {
    const store = useStore();
    let globalDocument = document;

    const handleBackToStoreClick = () => {
      globalDocument.location.href = `${
        store.getters[Getters.BVR__GET_CHECKOUT_DATA].failureRedirectUrl
      }`;
    };

    // Just for unit testing
    const mockGlobalDocument = (globalDocumentMock: any) => {
      globalDocument = globalDocumentMock;
    };

    return {
      mockGlobalDocument,
      handleBackToStoreClick,
    };
  },
};
