
import { AuthType } from '@/enums/AuthType';
import { BvrCheckoutData } from '@/models/BvrCheckoutData';
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as CommonGetters from "@/store/constants/Common/Getters";
import { computed, ComputedRef, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "AdditionalBonusAdView",
    setup() {
        const store = useStore();

        const isTargetMerchant: ComputedRef<Boolean> = computed(
            () => {
                const checkoutOptions = store.getters[CommonGetters.COMMON__GET_CHECKOUT_OPTIONS];
                const checkoutData: BvrCheckoutData = store.getters[Getters.BVR__GET_CHECKOUT_DATA];

                if(!checkoutOptions) {
                    return false;
                }

                const loyaltyMerchants = checkoutOptions.loyalityAdMerchants.split(";") || [];

                return  loyaltyMerchants.includes(checkoutData.merchantId?.toString());
            }
        );

        const isAuthTypeVerify: ComputedRef<boolean> = computed(() => {
            const checkoutData: BvrCheckoutData = store.getters[Getters.BVR__GET_CHECKOUT_DATA];

            return checkoutData.authType === AuthType.verify;
        });

        return {
            isTargetMerchant,
            isAuthTypeVerify
        }
    }
})
