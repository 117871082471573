import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0e2e3324"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "counter-caption" }
const _hoisted_2 = { style: {"color":"#4285f4"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("article", null, [
      _createTextVNode(" Через "),
      _createElementVNode("span", _hoisted_2, _toDisplayString($props.timeCounterValue), 1),
      _createTextVNode(" " + _toDisplayString($setup.timeCounterWordVariant) + " кнопка буде активна ", 1)
    ], 512), [
      [_vShow, $setup.canShowCounter]
    ])
  ]))
}