
import { AuthType } from '@/enums/AuthType';
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import { computed, ComputedRef, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "LoyaltyPointsPanel",
    props: {
        authType: {
            type: Number,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    setup (props: any) {
        const store = useStore();
        const isVerify = ref(props.authType == AuthType.verify);

        const bonusAsString: ComputedRef<String> = computed(() => {
            let amount = Math.floor(store.getters[Getters.BVR__GET_LOYALTY_POINTS_AMOUNT] || 0);
            let unitsDescription = '';

            const set1 = parseInt(amount.toString().slice(-1));
            const set2 = parseInt(amount.toString().slice(-2));

            if (set2 >= 5 && set2 <= 20 || set1 === 0) {
              unitsDescription =  "балобонусів";
            } else if (set1 == 1) {
              unitsDescription =  "балобонус";
            } else if (set1 > 1 && set1 < 5) {
              unitsDescription =  "балобонуса";
            } else {
              unitsDescription =  "балобонусів";
            }

            if (props.authType === (AuthType.verify as number)) {
                return "+багато балобонусів";
            }
            return `+ ${amount} ${unitsDescription}`;
        });

        const isBonusAmountVisible: ComputedRef<boolean> = computed(() => {
          const bonusAmount = store.getters[Getters.BVR__GET_LOYALTY_POINTS_AMOUNT];

          return !!bonusAmount && bonusAmount !== 0 && props.authType !== (AuthType.verify as number);
        })

        return {
            isVerify,
            isBonusAmountVisible,
            bonusAsString
        }
    }
})
