
import { computed, ComputedRef } from "vue";

export default {
  name: "PaymentConfirmationControlPanel",
  emits: ["confirm"],
  props: {
    disabled: Boolean,
    buttonLabel: String,
    isWaiting: Boolean,
  },
  setup(props: any) {
    const isDisabled: ComputedRef<boolean | undefined> = computed(() => props.disabled || props.isWaiting);
    return {
      isDisabled
    }
  }
};
