
import PushConfirmReminder from "@/components/PaymentDialogSmall/child-components/PushConfirmReminder.vue";
import { CheckoutScreen } from "@/enums/CheckoutScreen";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { computed, ComputedRef } from "vue";
import { useStore } from "vuex";

const PUSH_NOTIFICATION_DURATION = 5000;

export default {
  name: "PaymentDialogSmall",
  components: {
    PushConfirmReminder,
    LottieAnimation,
  },
  props: {
    isLoading: Boolean,
  },
  setup() {
    const store = useStore();

    const currentScreen = store.getters[Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN];

    const showAnimation: ComputedRef<boolean> = computed(() => {
      const currentScreen = store.getters[Getters.BVR__GET_CURRENT_CHECKOUT_SCREEN];
      return currentScreen === CheckoutScreen.PaymentConfirmation;
    });

    if (currentScreen === CheckoutScreen.PaymentConfirmation) {
      store.dispatch(
        Actions.BVR__SHOW_PUSH_CONFIRM_NOTIFICATION,
        PUSH_NOTIFICATION_DURATION
      );
    }

    const isPushConfirmNotificationVisible: ComputedRef<boolean> = computed(
      () => store.getters[Getters.BVR__GET_IS_PUSH_CONFIRM_REMINDER_VISIBLE]
    );

    return {
      isPushConfirmNotificationVisible,
      showAnimation
    };
  },
};
