
import { computed, ComputedRef } from 'vue';

export default {
  name: "PushNotificationSendDelayCounter",
  props: {
    timeCounterValue: Number,
  },
  setup(props: any) {
    const canShowCounter: ComputedRef<boolean> = computed(() => {
      return props.timeCounterValue && props.timeCounterValue > 0;
    });

    const timeCounterWordVariant: ComputedRef<String> = computed(() => {
      let variant = "секунд";

      const lastDigit: number = parseInt(
        props.timeCounterValue.toString().slice(-1)
      );

      if (props.timeCounterValue >= 5 && props.timeCounterValue <= 20) {
        return "секунд";
      }
      if (lastDigit == 1) {
        return "секунду";
      }
      if (lastDigit > 1 && lastDigit < 5) {
        return "секунди";
      }

      return variant;
    });


    return {
      canShowCounter,
      timeCounterWordVariant
    }
  }
};
