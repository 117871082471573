
import { ref, Ref } from "vue";

export default {
  name: "PhoneNumberPanel",
  emits: ["input"],
  props: {
    errorMessage: String,
    disable: Boolean,
    isError: Boolean,
    phoneNumber: Number,
  },
  setup(props: any, { emit }: { emit: any }) {
    const phone: Ref<string> = ref("");

    if (props.phoneNumber) {
      phone.value = props.phoneNumber.toString().substring(2);
    }

    const handleNumberInput = (value: string): void => {
      if (!value || value === "") {
        return;
      }
      const formattedPhoneNumber = parseInt(`38${value.replace(/\D/g, "")}`);
      emit("input", formattedPhoneNumber);
    };

    return {
      phone,
      handleNumberInput,
    };
  },
};
