import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    ref: "backToStoreButton",
    "no-caps": "",
    class: "dialog-button",
    label: "До магазину",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleBackToStoreClick()))
  }, null, 512))
}