
import BackToStoreButton from "@/common-components/BackToStoreButton.vue";
import { AuthType } from "@/enums/AuthType";
import MerchantInfoCache from "@/models/cache/MerchantInfoCache";
import BvrCheckoutData from "@/models/requests/BvrApproveRequest";
import * as Actions from "@/store/constants/BvrPayCheckout/Actions";
import * as Getters from "@/store/constants/BvrPayCheckout/Getters";
import * as Mutations from "@/store/constants/BvrPayCheckout/Mutations";
import AdditionalBonusAdView from "@/temp-components/AdditionalBonusAdView.vue";
import { computed, ComputedRef, ref } from "vue";
import { useStore } from "vuex";
import PaymentAvailableAttemptsView from "./child-components/PaymentAvailableAttemptsView.vue";
import PaymentConfirmationControlPanel from "./child-components/PaymentConfirmationControlPanel.vue";
import PaymentConfirmationDialogHeader from "./child-components/PaymentConfirmationDialogHeader.vue";
import PaymentErrorView from "./child-components/PaymentErrorView.vue";
import PaymentInfoPanel from "./child-components/PaymentInfoPanel.vue";
import PaymentVerifyPanel from "./child-components/PaymentVerifyPanel.vue";
import PhoneNumberPanel from "./child-components/PhoneNumberPanel.vue";
import PushNotificationSendDelayCounter from "./child-components/PushNotificationSendDelayCounter.vue";

export default {
  name: "PaymentConfirmation",
  components: {
    PaymentVerifyPanel,
    PaymentConfirmationDialogHeader,
    PaymentConfirmationControlPanel,
    PushNotificationSendDelayCounter,
    PaymentAvailableAttemptsView,
    AdditionalBonusAdView,
    PaymentInfoPanel,
    PhoneNumberPanel,
    PaymentErrorView,
    BackToStoreButton,
  },
  setup() {
    const store = useStore();
    const contentReady = ref(false);

    const errorCode: ComputedRef<number> = computed(
      () => store.getters[Getters.BVR__GET_APPROVE_ERROR]
    );

    const isVerifyPayment: ComputedRef<Boolean> = computed(
      () => store.getters[Getters.BVR__GET_CHECKOUT_DATA].authType == AuthType.verify
    );

    const isApproveError: ComputedRef<Boolean> = computed(
      () => !!errorCode.value
    );

    const headerCaption: ComputedRef<String> = computed(() => {
      const authType = store.getters[Getters.BVR__GET_CHECKOUT_DATA].authType;
      if (authType == AuthType.verify) {
        return "Перевірка картки";
      }
      return "Підтвердь оплату в додатку";
    });

    const hideOrderNumber: ComputedRef<boolean> = computed(() => {
      return store.getters[Getters.BVR__GET_IS_ORDER_NUMBER_HIDDEN];
    })

    const isPaymentSuspend: ComputedRef<boolean> = computed(() => store.getters[Getters.BVR__GET_IS_CHECKOUT_PAYMENT_SUSPEND]);

    const availableConfirmationAttemptCount: ComputedRef<number> = computed(
      () =>
        store.getters[Getters.BVR__GET_AVAILABLE_PAYMENT_AUTHORIZATION_ATTEMPTS_LEFT]
    );

    const bvrCheckoutData: ComputedRef<BvrCheckoutData> = computed(
      () => store.getters[Getters.BVR__GET_CHECKOUT_DATA]
    );

    const isLoyaltyPointsLoading: ComputedRef<boolean> = computed(() => store.getters[Getters.BVR__GET_IS_LOYALTY_POINTS_LOAD_WAITING]);

    const merchantInfo: ComputedRef<MerchantInfoCache> = computed(
      () => store.getters[Getters.BVR__GET_MERCHANT_INFO]
    );

    const isApproveButtonDisabled: ComputedRef<boolean> = computed(
      () => store.getters[Getters.BVR__GET_APPROVE_BUTTON_LOCK_STATE]
    );

    const phoneNumberInputErrorMessage: ComputedRef<string> = computed(
      () => store.getters[Getters.BVR__GET_CLIENT_PHONE_INPUT_ERROR_MESSAGE]
    );

    const isPhoneNumberInputError: ComputedRef<boolean> = computed(
      () => store.getters[Getters.BVR__GET_IS_CLIENT_PHONE_INPUT_ERROR]
    );

    const pushSuspensionTime: ComputedRef<number> = computed(
      () => store.getters[Getters.BVR__GET_PUSH_SEND_SUSPENSION_TIME_LEFT]
    );

    const canChangePhoneNumber: ComputedRef<boolean> = computed(
      () => store.getters[Getters.BVR__GET_MERCHANT_INFO].canChangePhoneNumber
    );

    const failureRedirectUrl: ComputedRef<string> = computed(
      () => store.getters[Getters.BVR__GET_CHECKOUT_DATA].failureRedirectUrl
    );

    const handlePaymentConfirmation = () => {
      const checkoutData: BvrCheckoutData =
        store.getters[Getters.BVR__GET_CHECKOUT_DATA];
      if (checkoutData.phoneNumber!.toString().length < 12) {
        store.commit(
          Mutations.BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE,
          "Некоректний формат номеру телeфона"
        );
        store.commit(Mutations.BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR, true);
      } else {
        store.commit(Mutations.BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE, "");
        store.commit(Mutations.BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR, false);
        store.dispatch(Actions.BVR__CONFIRM_PAYMENT);
      }
    };

    const handlePhoneNumberInput = (value: number) => {
      const bvrCheckoutData: BvrCheckoutData =
        store.getters[Getters.BVR__GET_CHECKOUT_DATA];

      if (!canChangePhoneNumber.value) {
        return;
      }

      if (value.toString().length === 12) {
        store.commit(Mutations.BVR__SET_CLIENT_PHONE_INPUT_ERROR_MESSAGE, "");
        store.commit(Mutations.BVR__SET_IS_CLIENT_PHONE_INPUT_ERROR, false);
      }
      store.commit(Mutations.BVR__SET_CHECKOUT_DATA, {
        ...bvrCheckoutData,
        phoneNumber: value,
      });
    };

    return {
      bvrCheckoutData,
      contentReady,
      phoneNumberInputErrorMessage,
      isPhoneNumberInputError,
      handlePaymentConfirmation,
      isLoyaltyPointsLoading,
      handlePhoneNumberInput,
      canChangePhoneNumber,
      pushSuspensionTime,
      isApproveButtonDisabled,
      availableConfirmationAttemptCount,
      isApproveError,
      errorCode,
      merchantInfo,
      headerCaption,
      isVerifyPayment,
      isPaymentSuspend,
      failureRedirectUrl,
      hideOrderNumber
    };
  },
};
